#login-bg {
  /* Location of the image */
  background-image: url('../Images/ejavec-forum-background-email.jpg');

  /* Image is centered vertically and horizontally at all times */
  background-position: center center;

  /* Image doesn't repeat */
  background-repeat: no-repeat;

  /* Makes the image fixed in the viewport so that it doesn't move when 
     the content height is greater than the image height */
  background-attachment: fixed;

  /* This is what makes the background image rescale based on its container's size */
  background-size: cover;

  /* Pick a solid background color that will be displayed while the background image is loading */
  background-color: #464646;

  /* SHORTHAND CSS NOTATION
  * background: url(background-photo.jpg) center center cover no-repeat fixed;
  */

  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin-top: 20px;
  padding: 0;
}

.pagination a {
  cursor: default;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #6c7ac9;
  color: #6c7ac9;
  margin-left: 10px;
}

.pagination li:not(.disabled) a:hover {
  background-color: bisque;
  cursor: pointer;
}

.pagination li a {
  font-weight: bold;
}

.pagination li.active a {
  color: #fff;
  background: #6c7ac9;
}

.pagination li.disabled a {
  pointer-events: none;
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}

/* SweetAlert2: Blurry Background */
/* body.swal2-shown > [aria-hidden='true'] {
  transition: 0.1s filter;
  filter: blur(10px);
} */
